import _ from "lodash";
import { makeAutoObservable, observable } from "mobx";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { RootStore } from ".";
import { colors as ThemeColors } from "../theme";
import {
  Activity,
  ActivityCategory,
  Event,
  GroupMember,
  ReservationGroup,
  ReservationRequest,
  ReservationRequestActivity,
  SelectedActivity,
} from "../types";

const colors = ThemeColors.slice(0, -2);

export class ActivityStore {
  rootStore;
  selectedDate: moment.Moment = moment();
  _filterSettings = {
    onlyAvailable: false,
    categories: Object.values(ActivityCategory),
  };
  _availableActivities = observable.map<string, Activity>([]);
  _selectedActivities = observable.map<string, SelectedActivity>([]);
  _group = observable.map<string, GroupMember>([
    [
      "319b571d-b6e1-4e3f-b94e-45e937e3b6c8",
      {
        id: "319b571d-b6e1-4e3f-b94e-45e937e3b6c8",
        name: "Person 1",
        email: "",
        color: colors[Math.floor(Math.random() * colors.length)],
      },
    ],
  ]);
  isLoadingActivities = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, { rootStore: false }, { autoBind: true });
    this.rootStore = rootStore;
    this.init();
  }

  async init() {
    this.restoreCartFromLocalStorage();
    this.removeCartFromLocalStorage();

    // autorun(() => {
    //   if
    //   this.saveCartToLocalStorage();
    // })
  }

  async searchActivities() {
    this.resetSelections();
    await this.fetchActivities();
  }

  *fetchActivities() {
    this.isLoadingActivities = true;

    const { data } = yield this.rootStore.services.api.getActivities(
      (this.selectedDate || moment()).toISOString().slice(0, 10)
    );

    if (Array.isArray(data)) {
      this.isLoadingActivities = false;
      this._availableActivities = this._availableActivities.merge(
        data.map((activity: any): [string, Activity] => [
          activity.id,
          {
            id: activity.id,
            name: activity.name,
            description: activity.description,
            source: activity.source,
            vendor: activity.vendor,
            vendorId: activity.vendorId,
            basePrice: activity.basePrice,
            slots: activity.slots,
            duration: activity.duration,
            category: activity.category,
            previewImageUrl: activity.previewImageUrl,
            hasOnlyExternalReservation: activity.hasOnlyExternalReservation,
            externalReservationLink: activity.externalReservationLink,
            isGroupActivity: activity.isGroupActivity,
            sourceData: activity.sourceData,
          },
        ])
      );
    } else {
      throw new Error("No activities found");
    }
  }

  async createReservations() {
    const reservationRequests: ReservationRequest[] = [];

    this.selectedActivitiesWithActivityAndSlot.forEach((selectedActivity) => {
      if (!selectedActivity.slotId) throw new Error("SlotId not selected");

      const slot = this.getSlot(selectedActivity.slotId);
      const activity = this.getActivity(selectedActivity.id);

      if (!activity || !slot) throw new Error("Slot or activity not found");
      if (!this.selectedDate) throw new Error("Date not selected");

      const { slots, ...activityWithoutSlots } = activity;

      const reservationActivity: ReservationRequestActivity = {
        ...activityWithoutSlots,
        slot,
      };

      if (activity.isGroupActivity) {
        reservationRequests.push({
          activity: reservationActivity,
          selectedDate: this.selectedDate.toISOString().slice(0, 10),
          visitorName: "",
          visitorEmail: "",
        });
      } else {
        selectedActivity.participants.forEach((participantId) => {
          // console.log("participantId", participantId);

          const participant = this.group.find(
            (member) => member.id === participantId
          );

          if (participant && slot && this.selectedDate) {
            const request: ReservationRequest = {
              activity: reservationActivity,
              selectedDate: this.selectedDate.toISOString().slice(0, 10),
              visitorName: participant.name,
              visitorEmail: participant.email,
            };
            reservationRequests.push(request);
          }
        });
      }
    });

    const { status, data } =
      await this.rootStore.services.api.createReservations({
        selectedDate: this.selectedDate.toISOString().slice(0, 10),
        reservations: reservationRequests,
      });

    return { status, data };
  }

  async updateReservationGroup(
    reservationId: string,
    update: Partial<ReservationGroup>
  ) {
    await this.rootStore.services.api.updateReservationGroup(
      reservationId,
      update
    );
  }

  async confirmReservations(reservationGroupId: string) {
    return await this.rootStore.services.api.confirmReservations(
      reservationGroupId
    );
  }

  get availableActivities() {
    return (
      _.chain(Array.from(this._availableActivities.values()))
        .sortBy("name")
        .filter((activity) => {
          let categoryExcluded = false;
          let noSlots = false;
          if (this.filterSettings.onlyAvailable) {
            noSlots = activity.slots.length == 0;
          }
          categoryExcluded = !this.filterSettings.categories.includes(
            activity.category
          );
          return !categoryExcluded && !noSlots;
        })
        .value() || []
    );
  }

  get selectedActivities() {
    return Array.from(this._selectedActivities.values());
  }

  getActivity(id: string) {
    return this._availableActivities.get(id);
  }

  get group(): GroupMember[] {
    return Array.from(this._group.values());
  }

  get selectedDayIsOnWeekend() {
    // If the selected day is a weekend day (friday, saturday, sunday), admission is required
    const dayOfWeek = this.selectedDate.day();
    const selectedDayIsOnWeekend = [0, 5, 6].includes(dayOfWeek);

    // Disable this logic for now until ticketing should be rolled out
    // return selectedDayIsOnWeekend;
    return false;
  }

  get hasSelectedActivityWithUpfrontPayment() {
    // If one of the selected activities has sourceData.attributes.requireUpfrontPayment admission is required
    return this.selectedActivities.some((activity) => {
      const activityData = this.getActivity(activity.id);
      if (!activityData) throw new Error("Activity not found");
      return activityData.sourceData?.attributes?.requireUpfrontPayment;
    });
  }

  get filterSettings() {
    return this._filterSettings;
  }

  activityParticipants(participants: string[]) {
    return this.group.filter((member) => participants.includes(member.id));
  }

  selectSlot(id: string, slotId: string) {
    const activity = this._availableActivities.get(id);
    const selectedActivity = this._selectedActivities.get(id);
    if (activity) {
      this._selectedActivities.set(id, {
        id: activity.id,
        slotId,
        participants: selectedActivity?.participants || [],
        addedAt: selectedActivity?.addedAt || Date.now(),
      });
    }
  }

  deselectSlot(id: string) {
    const activity = this._availableActivities.get(id);
    const selectedActivity = this._selectedActivities.get(id);
    if (activity && selectedActivity) {
      if (activity.isGroupActivity) {
        this._selectedActivities.delete(id);
      } else {
        this._selectedActivities.set(id, {
          ...selectedActivity,
          slotId: undefined,
        });
      }
    } else {
      throw new Error("Activity not found");
    }
  }

  selectParticipant(activityId: string, participantId: string) {
    const activity = this._availableActivities.get(activityId);
    const selectedActivity = this._selectedActivities.get(activityId);
    if (activity) {
      this._selectedActivities.set(activityId, {
        id: activityId,
        participants: (selectedActivity?.participants || []).concat(
          participantId
        ),
        slotId: selectedActivity?.slotId || undefined,
        addedAt: selectedActivity?.addedAt || Date.now(),
      });
    }
  }

  addGroupMember() {
    const id = uuidv4();
    this._group.set(id, {
      id,
      name: "Person " + (this.group.length + 1),
      email: "",
      color: colors[Math.floor(Math.random() * colors.length)],
    });
  }

  getGroupMember(id: string) {
    return this._group.get(id);
  }

  removeGroupMember(id: string) {
    if (this._group.size > 1) {
      this._group.delete(id);
    }
  }

  changeGroupMemberName(id: string, name: string) {
    const member = this._group.get(id);
    if (member) {
      this._group.set(id, { ...member, name });
    }
  }

  changeGroupMemberEmail(id: string, email: string) {
    const member = this._group.get(id);
    if (member) {
      this._group.set(id, { ...member, email });
    }
  }

  deselectParticipant(activityId: string, participantId: string) {
    const activity = this._selectedActivities.get(activityId);
    if (activity) {
      this._selectedActivities.set(activityId, {
        ...activity,
        participants: activity.participants.filter(
          (id) => id !== participantId
        ),
      });
    }
  }

  getSlot(slotId: string) {
    return this.availableActivities
      .map((activity) => activity.slots)
      .flat()
      .find((slot) => slot.id === slotId);
  }

  slotSelected(id: string, slotId: string) {
    const activity = this._selectedActivities.get(id);
    return activity?.slotId === slotId || false;
  }

  hasSlotSelected(id: string) {
    const activity = this._selectedActivities.get(id);
    return !!activity?.slotId;
  }

  get hasSelectedActivities() {
    return !this.selectedActivities.some((a) => !!a.slotId);
  }

  hasParticipantsSelected(id: string) {
    const activity = this._selectedActivities.get(id);
    return !!activity?.participants.length;
  }

  countSelectedParticipants(id: string) {
    const activity = this._selectedActivities.get(id);
    return activity?.participants.length || 0;
  }

  participantSelected(id: string, participantId: string) {
    const activity = this._selectedActivities.get(id);
    return activity?.participants.includes(participantId) || false;
  }

  setSelectedDate(date: moment.Moment) {
    this.selectedDate = date;
  }

  resetSelections() {
    this._selectedActivities.clear();
    this.rootStore.stores.activityStore.removeCartFromLocalStorage();
  }

  updateFilterSettings({ key, value }: { key: string; value: any }) {
    this._filterSettings = { ...this._filterSettings, [key]: value };
  }

  get selectedActivitiesWithActivityAndSlot() {
    return this.selectedActivities
      .filter((selected) => {
        const activity = this.getActivity(selected.id);
        const slot = this.selectedActivitiesAsTimedEvents.filter(
          (s) => s.id === selected.slotId
        )[0];
        return activity && slot;
      })
      .map((selected) => {
        const activity = this.getActivity(selected.id);
        const slot = this.selectedActivitiesAsTimedEvents.filter(
          (s) => s.id === selected.slotId
        )[0];

        if (!activity || !slot) throw new Error("Activity or slot not found");

        return {
          ...selected,
          activity,
          slot,
        };
      });
  }

  get selectedActivitiesAsTimedEvents(): Event[] {
    return this.selectedActivities
      .filter((sa) => {
        const activity = this.getActivity(sa.id);
        const hasSlot = !!sa.slotId;
        const isGroupActivity = activity?.isGroupActivity;
        const hasParticipants = sa.participants.length > 0;

        if (hasSlot && isGroupActivity) return true;
        if (hasSlot && hasParticipants) return true;
        return false;
      })
      .map((selected) => {
        const activity = this.getActivity(selected.id);
        const slot = activity?.slots.find(
          (slot: any) => slot.id === selected.slotId
        );

        if (!activity || !slot) throw new Error("Activity or slot not found");

        const end = moment(slot?.start).add(activity?.duration, "minutes");

        const participants = selected.participants.map((p) =>
          this.getGroupMember(p)
        );

        const color =
          activity.isGroupActivity || selected.participants.length > 1
            ? (ThemeColors.at(-1) as string)
            : participants[0]?.color || (ThemeColors.at(-1) as string);

        return {
          id: slot?.id,
          start: moment(slot?.start).format("HH:mm"),
          end: end.format("HH:mm"),
          title: activity?.name || "",
          participants: participants.map((p) => p?.name || ""),
          color: color,
          addedAt: selected.addedAt,
        };
      });
  }

  saveCartToLocalStorage() {
    localStorage.setItem(
      "cart",
      JSON.stringify({
        selectedActivities: this.selectedActivities,
        availableActivities: this.availableActivities,
        group: this.group,
        selectedDate: this.selectedDate,
      })
    );
  }

  restoreCartFromLocalStorage() {
    const cart = localStorage.getItem("cart");
    if (cart) {
      const { selectedActivities, group, selectedDate, availableActivities } =
        JSON.parse(cart);

      this._availableActivities = this._availableActivities.merge(
        availableActivities.map((activity: any) => [activity.id, activity])
      );
      this._group = this._group.merge(
        group.map((member: any) => [member.id, member])
      );
      this._selectedActivities = this._selectedActivities.merge(
        selectedActivities.map((activity: any) => {
          return [activity.id, activity];
        })
      );
      this.selectedDate = moment(selectedDate);
    }
  }

  removeCartFromLocalStorage() {
    localStorage.removeItem("cart");
  }
}
